import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import classes from "./styles.module.scss";
import clsx from "classnames";
import YoutubeVideo from "@/ui/YoutubeVideo";
import Play from "./Play.svg";
import { useMediaQuery } from "@react-hook/media-query";

export default function YoutubeVideoModal({ className, variant, video_embed_url, children, ...props }) {
  const breakpoint = useMediaQuery("only screen and (min-width: 768px)");
  const [isMobile, setMobile] = useState(true);
  const [show, setShow] = useState(false);
  const triggerProps = useMemo(
    () => ({
      className: clsx(classes.video__trigger),
      onClick: typeof children === "function" ? undefined : () => setShow(true),
      ...props,
    }),
    [children, props]
  );

  useEffect(() => {
    setMobile(!breakpoint);
  }, [breakpoint]);

  if (!video_embed_url) {
    return <div className={clsx(className)}>{children}</div>;
  }

  return (
    <div className={clsx(className, classes.video, `variant-${variant}`)}>
      {isMobile ? (
        <YoutubeVideo video_embed_url={video_embed_url} autoplay={false} />
      ) : (
        <>
          <div {...triggerProps}>
            <i className={classes.video__triggerIcon}>
              <Play />
            </i>
            <div className={classes.video__children}>
              {typeof children === "function" ? children({ show, setShow }) : children}
            </div>
          </div>
          <Modal show={show} size="xl" className={classes.video__modal} onHide={() => setShow(false)}>
            <YoutubeVideo video_embed_url={video_embed_url} />
          </Modal>
        </>
      )}
    </div>
  );
}

YoutubeVideoModal.defaultProps = {
  className: "",
  variant: "primary",
  video_embed_url: null,
};

YoutubeVideoModal.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary", "quinary", "senary"]),
  video_embed_url: PropTypes.string,
};
