import { Col, Container, Row } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { Details } from "@/ui/Details";
import { useLocale } from "@/hooks/useLocale";

export const SeriousAboutTalent = ({ className, titleClassName, tag, title, text, details, children, lg, variant }) => {
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.SeriousAboutTalent)} data-variant={variant}>
      <Container className={clsx("px-2 px-md-3", { "pt-5": !!tag })}>
        {tag && <DynamicText path={tag} className={classes.SeriousAboutTalent__tag} />}
        <Row className={clsx("position-relative", { "pt-md-5": !!tag })}>
          <Col xs={12} lg={lg} className="px-3">
            <div className={clsx(classes.SeriousAboutTalent__content, "mt-4 me-3 mb-4")}>
              <DynamicText
                path={typeof title === "string" ? title : `${title.path}.${locale}`}
                tag="h3"
                className={clsx("h4", classes.SeriousAboutTalent__title, titleClassName)}
                dangerouslySetInnerHTML={title.placeholders ? { __html: title.placeholders[locale] } : null}
              />
              <DynamicHtml path={text} />
              {details && (
                <Details>
                  <DynamicHtml path={details} />
                </Details>
              )}
            </div>
          </Col>
          {children}
        </Row>
      </Container>
    </div>
  );
};

SeriousAboutTalent.defaultProps = {
  lg: 6,
  variant: "primary",
  titleClassName: "h4",
};
